<template>
  <b-container fluid class="account-headers accounts-tabs-height">
    <b-row>
      <b-col cols="1" class="text-center">
        <b-img src="/img/account-icons.svg"></b-img>
      </b-col>
      <b-col cols="11">
        <b-row>
          <b-col cols="12">
            <h2>{{$t("profile.infotexts.security-title")}}</h2>
            <p>
                {{$t("profile.infotexts.security")}}
            </p>
            <hr />
          </b-col>
        </b-row>
        <b-row class="account-sub-header">
          <b-col cols="8">
            <h3 class="text-left w-100 sub-title mt-4 mb-4">{{$t("profile.contents.myprofile.security.youremail")}}</h3>
            <validation-observer ref="emailObserver">
              <form action="">
                <validation-provider
                  name="New Email"
                  :rules="{ required: true, email: true }"
                  v-slot="validationContext"
                >
                  <div class="form-group mt-4 mb-0">
                    <label for="email">{{$t("profile.contents.myprofile.security.email")}}</label>
                    <input spellcheck="true"
                      :disabled="emailRequest"
                      v-model.trim="form.email"
                      id="email"
                      type="email"
                      class="form-control custom"
                    />
                  </div>
                  <div v-if="validationContext.errors[0]" class="text-danger">
                    <i class="imgs icon-warning"></i>
                    {{ validationContext.errors[0] }}
                  </div>
                </validation-provider>
                <div class="d-flex justify-content-end my-5">
                  <button
                    :disabled="emailRequest"
                    @click.prevent="getEmailRequest"
                    class="btn btn-outline-primary "
                  >
                    {{ emailRequest ? "Loading.." : $t('buttons.changemail') }}
                  </button>
                </div>
              </form>
            </validation-observer>
          </b-col>
          <b-col cols="8">
            <h3 class="text-left w-100 sub-title mt-4 mb-4">{{$t("profile.contents.myprofile.security.changepass")}}</h3>
            <validation-observer ref="passwordChangeObServe">
              <form action="#">
                <validation-provider
                  name="old password"
                  :rules="{ required: true, min: 8 }"
                  v-slot="validationContext"
                >
                  <div class="form-group mt-4 mb-0">
                    <label for="oldPw">{{$t("profile.contents.myprofile.security.oldpass")}}</label>
                    <input spellcheck="true"
                      :disabled="pwLoading"
                      v-model.trim="form.oldPw"
                      id="oldPw"
                      type="password"
                      class="form-control custom"
                    />
                  </div>
                  <div v-if="validationContext.errors[0]" class="text-danger">
                    {{ validationContext.errors[0] }}
                  </div>
                </validation-provider>
                <validation-provider
                  name="new password"
                  :rules="{ required: true, min: 8 }"
                  v-slot="validationContext"
                >
                  <div class="form-group mt-4 mb-0">
                    <label for="newPw">{{$t("profile.contents.myprofile.security.newpass")}}</label>
                    <input spellcheck="true"
                      :disabled="pwLoading"
                      v-model.trim="form.newPw"
                      id="newPw"
                      type="password"
                      class="form-control custom"
                    />
                  </div>
                  <div v-if="validationContext.errors[0]" class="text-danger">
                    {{ validationContext.errors[0] }}
                  </div>
                </validation-provider>
                <small class="info-text">
                 {{$t("profile.contents.myprofile.security.alert-1")}}
                </small>
                <div class="d-flex justify-content-end my-5">
                  <button
                    :disabled="pwLoading"
                    @click.prevent="changePassword"
                    class="btn btn-outline-primary "
                  >
                    {{ pwLoading ? "Loading.." : $t('buttons.savechanges') }}
                  </button>
                </div>
              </form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      :no-close-on-esc="codeLoading"
      :hide-header-close="codeLoading"
      :no-close-on-backdrop="true"
      centered
      id="emailCodeModal"
      :title="$t('buttons.changemail')"
    >

    
      <label for="emailCode">{{$t('General.enter-email')}}</label>
      <input spellcheck="true"
        :disabled="codeLoading"
        id="emailCode"
        maxlength="6"
        type="text"
        @input="forceUppercase($event, form, 'emailCode')"
        v-model="form.emailCode"
        class="form-control"
        :placeholder="$t('General.enter-email-ph')"
      />
      <template #modal-footer="{ ok, cancel, hide }">
        <div>
          <button
            :disabled="codeLoading"
            class="btn btn-outline-primary btn-sm mr-3"
            @click="hide"
          >
            {{$t("buttons.cancel")}}
          </button>
          <button
            :disabled="codeLoading"
            @click="sendEmailCode"
            class="btn btn-primary btn-sm"
          >
            {{ codeLoading ? "Loading" : "OK" }}
          </button>
        </div>
      </template>
    </b-modal>
  </b-container>
  <!--<div class="container">
    <div class="row justify-content-between">
      <div class="col-6">
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-11">
                <h3 class="text-left w-100 sub-title mt-4 mb-4">Your E-mail</h3>
                <validation-observer ref="emailObserver">
                  <form action="">
                    <validation-provider
                      name="New Email"
                      :rules="{ required: true, email: true }"
                      v-slot="validationContext"
                    >
                      <div class="form-group mt-4 mb-0">
                        <label for="email">E-mail</label>
                        <input spellcheck="true"
                          :disabled="emailRequest"
                          v-model.trim="form.email"
                          id="email"
                          type="email"
                          class="form-control custom"
                        />
                      </div>
                      <div
                        v-if="validationContext.errors[0]"
                        class="text-danger"
                      >
                        <i class="imgs icon-warning"></i>
                        {{ validationContext.errors[0] }}
                      </div>
                    </validation-provider>
                    <div class="d-flex justify-content-end my-5">
                      <button
                        :disabled="emailRequest"
                        @click.prevent="getEmailRequest"
                        class="btn btn-outline-primary"
                      >
                        {{ emailRequest ? "Loading.." : "Change E-mail" }}
                      </button>
                    </div>
                  </form>
                </validation-observer>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-11">
                <h3 class="text-left w-100 sub-title mt-4 mb-4">
                  Set Password
                </h3>
                <validation-observer ref="passwordChangeObServe">
                  <form action="#">
                    <validation-provider
                      name="old password"
                      :rules="{ required: true, min: 8 }"
                      v-slot="validationContext"
                    >
                      <div class="form-group mt-4 mb-0">
                        <label for="oldPw">Old Password</label>
                        <input spellcheck="true"
                          :disabled="pwLoading"
                          v-model="form.oldPw"
                          id="oldPw"
                          type="password"
                          class="form-control custom"
                        />
                      </div>
                      <div
                        v-if="validationContext.errors[0]"
                        class="text-danger"
                      >
                        {{ validationContext.errors[0] }}
                      </div>
                    </validation-provider>
                    <validation-provider
                      name="new password"
                      :rules="{ required: true, min: 8 }"
                      v-slot="validationContext"
                    >
                      <div class="form-group mt-4 mb-0">
                        <label for="newPw">New Password</label>
                        <input spellcheck="true"
                          :disabled="pwLoading"
                          v-model="form.newPw"
                          id="newPw"
                          type="password"
                          class="form-control custom"
                        />
                      </div>
                      <div
                        v-if="validationContext.errors[0]"
                        class="text-danger"
                      >
                        {{ validationContext.errors[0] }}
                      </div>
                    </validation-provider>
                    <small class="info-text">
                      8 characters or longer. Combine upper and lowercase
                      letters and numbers.
                    </small>
                    <div class="d-flex justify-content-end my-5">
                      <button
                        :disabled="pwLoading"
                        @click.prevent="changePassword"
                        class="btn btn-outline-primary"
                      >
                        {{ pwLoading ? "Loading.." : "Save Changes" }}
                      </button>
                    </div>
                  </form>
                </validation-observer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="setting-info-card">
          <div class="icon">
            <v-icon :icon="['fac', 'bulb']" />
          </div>
          <h3 class="title">Info Updates</h3>
          <hr class="w-75" />
          <p>
            It is recomended that you should change your e-mail adreess only if
            you company is changed.
          </p>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import emailChangeRequest from "@/graphql/me/profile/emailChangeRequest.graphql";
import sendEmailCode from "@/graphql/me/profile/sendEmailCode.graphql";
import changePassword from "@/graphql/me/profile/changePassword.graphql";
import sha256 from "sha256";

export default {
  data() {
    return {
      emailRequest: false,
      isSubmitEmail: false,
      codeLoading: false,
      pwLoading: false,
      form: {
        email: null,
        emailCode: null,
        oldPw: null,
        newPw: null,
      },
    };
  },

  methods: {
    changePassword() {
      this.$refs.passwordChangeObServe.validate().then(async (success) => {
        if (!success) {
          return;
        }
        try {
          this.pwLoading = true;
          let passwords = {
            old_password: sha256(this.form.oldPw),
            new_password: sha256(this.form.newPw),
          };
          let data = await this.$apollo.mutate(changePassword, { passwords });
          let response = data("**.changePassword");
          if (response) {
            return this.$bvToast.toast(this.$t("Toaster.pass-changed"),
              {
                 title: this.$t("Toaster.success-title"),
                variant: "success",
                autoHideDelay: 1350,
              }
            );
          }
        } catch (e) {
          
            return this.$bvToast.toast(this.$t(e.message), {
            title: this.$t("Toaster.error-title"),
            variant: "danger",
            autoHideDelay: 1350,
          });
        } finally {
          this.pwLoading = false;
        }
      });
    },
    forceUppercase(e, o, prop) {
      const start = e.target.selectionStart;
      e.target.value = e.target.value.toUpperCase();
      this.$set(o, prop, e.target.value);
      e.target.setSelectionRange(start, start);
    },
    async sendEmailCode() {
      try {
        this.codeLoading = true;
        let payload = {
          code: this.form.emailCode,
        };
        let data = await this.$apollo.mutate(sendEmailCode, payload);
        let response = data("**.changeEmailApply");
        if (response) {
          this.$bvModal.hide("emailCodeModal");
          this.form.emailCode = null;
          location.reload();
        }
      } catch (e) {
       
         return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.codeLoading = false;
      }
    },
    async getEmailRequest() {
      this.$refs.emailObserver.validate().then(async (success) => {
        if (!success) {
          return;
        }
        try {
          this.emailRequest = true;
          if (this.GET_USER_INFO.email === this.form.email) {
            this.emailRequest = false;
           return this.$bvToast.toast(this.$t("Toaster.email-same"),{
              title: this.$t("Toaster.info-title"),
              variant: "info",
              autoHideDelay: 1350,
            });
          }
          let payload = {
            email: this.form.email,
          };
          let data = await this.$apollo.mutate(emailChangeRequest, payload);
          let response = data("**.changeEmailRequest");
          if (response) {
            this.isSubmitEmail = true;
            this.$bvModal.show("emailCodeModal");
          }
        } catch (e) {
         
          return this.$bvToast.toast(this.$t(e.message), {
             title: this.$t("Toaster.error-title"),
            variant: "danger",
            autoHideDelay: 1350,
          });
        } finally {
          this.emailRequest = false;
        }
      });
    },
  },
  created() {
    this.form.email = this.GET_USER_INFO.email;
  },
};
</script>
